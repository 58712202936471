import { ToasterContextProvider } from 'organism/Toaster';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppContext from '../AppContext';

const TermsPage = lazy(() => import('../../pages/Terms'));
const CoinsPage = lazy(() => import('../../pages/Coins'));
const DataListPage = lazy(() => import('../../pages/DataList'));
const DataListsPage = lazy(() => import('../../pages/DataLists'));
const HomePage = lazy(() => import('../../pages/Home'));
const OnboardingPage = lazy(() => import('../../pages/Onboarding'));
const PeoplePage = lazy(() => import('../../pages/People'));
const ProjectsPage = lazy(() => import('../../pages/Projects'));
const RoomsPage = lazy(() => import('../../pages/Rooms'));
const WorkgroupsPage = lazy(() => import('../../pages/Workgroups'));

interface IProps {
    data?: any;
}

const App: React.FC<IProps> = ({ data }) => (
    <BrowserRouter>
        <AppContext data={data}>
            <ToasterContextProvider>
                <Suspense fallback={<div />}>
                    <Routes>
                        <Route path="attendances" element={<OnboardingPage />} />
                        <Route path="rooms" element={<RoomsPage />} />
                        <Route path="people" element={<PeoplePage />} />
                        <Route path="workgroups" element={<WorkgroupsPage />} />
                        <Route path="coins" element={<CoinsPage />} />
                        <Route path="projects" element={<ProjectsPage />} />
                        <Route path="terms" element={<TermsPage />} />
                        <Route path="data_lists" element={<DataListsPage />} />
                        <Route path="data_lists/:id" element={<DataListPage />} />
                        <Route path="" element={<HomePage />} />
                    </Routes>
                </Suspense>
            </ToasterContextProvider>
        </AppContext>
    </BrowserRouter>
);

export default App;
