import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

import { ApolloClient, ApolloLink, InMemoryCache, Operation } from '@apollo/client';

import introspectionQueryResultData from './fragmentTypes.json';

import { createHttpLink } from '@apollo/react-hooks';
import { createConsumer } from '@rails/actioncable';

const buildClient = (uri: string = '/graphql') => {
    const httpLink = createHttpLink({ uri });

    const getCableUrl = () => {
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        let host = window.location.hostname;
        if (window.location.port) {
            host += ':' + window.location.port;
        }
        return `${protocol}//${host}/cable`;
    };

    const cable = createConsumer(getCableUrl());

    const hasSubscriptionOperation = (operation: Operation) => {
        const definitions = operation.query.definitions;
        return definitions.some((def: any) => def.kind === 'OperationDefinition' && def.operation === 'subscription');
    };

    const link = ApolloLink.split(hasSubscriptionOperation, new ActionCableLink({ cable }), httpLink);

    return new ApolloClient({
        link,
        cache: new InMemoryCache({
            possibleTypes: introspectionQueryResultData.possibleTypes
        })
    });
};

export default buildClient;
