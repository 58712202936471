import { createContext, useContext, useRef, useState } from 'react';
import ToasterContainer, { ToasterContainerRef } from './ToasterContainer';

const DEFAULT_TOASTER: ToasterContainerRef = {
    showToast: () => {}
};

const ToasterContext = createContext<React.MutableRefObject<ToasterContainerRef>>({ current: DEFAULT_TOASTER });

export const ToasterContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const toasterRef = useRef<ToasterContainerRef>(DEFAULT_TOASTER);

    return (
        <ToasterContext.Provider value={toasterRef}>
            {children}
            <ToasterContainer ref={toasterRef} />
        </ToasterContext.Provider>
    );
};

export const useToaster = () => useContext(ToasterContext);
