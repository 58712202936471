import Alpine from 'alpinejs';
window.Alpine = Alpine;

Alpine.start();

console.log(Alpine);

import '@tabler/core/src/js/tabler';

import { createRoot } from 'react-dom/client';
import App from 'organism/App';

const container = document.getElementById('root');
if (container != null) {
    const root = createRoot(container);
    const data = container.getAttribute('data-app') ?? '{}';
    root.render(<App data={JSON.parse(data)} />);
}
