import { ApolloProvider } from '@apollo/react-hooks';
import { AvatarModalProvider } from 'molecule/AvatarModal';
import * as React from 'react';
import buildClient from '../../lib/client';

interface IAppContext {
    instructor: boolean;
    admin: boolean;
    selectedTermId: string;
    projectSignupEnabled: boolean;
}

const ApplicationContext = React.createContext<IAppContext>({
    instructor: false,
    admin: false,
    selectedTermId: '',
    projectSignupEnabled: false
});

interface IProps {
    children: React.ReactNode;
    data: any;
}

const AppContext = ({ children, data }: IProps) => {
    const client = buildClient();

    const value = React.useMemo(
        () => ({
            instructor: data?.instructor === true,
            admin: data?.admin === true,
            selectedTermId: data?.term_id ?? '',
            projectSignupEnabled: data?.project_signup_enabled === true
        }),
        [data]
    );

    return (
        <ApplicationContext.Provider value={value}>
            <AvatarModalProvider>
                <ApolloProvider client={client}>{children}</ApolloProvider>
            </AvatarModalProvider>
        </ApplicationContext.Provider>
    );
};

const useAppContext = () => React.useContext(ApplicationContext);
export const useIsAdmin = () => useAppContext().admin;
export const useIsInstructor = () => useAppContext().instructor;
export const useSelectedTermId = () => useAppContext().selectedTermId;
export const useProjectSignupEnabled = () => useAppContext().projectSignupEnabled;

export default AppContext;
