import * as React from 'react';
import Message, { IMessageProps } from 'atom/Message';

export interface ToasterContainerRef {
    showToast: ({
        message,
        description,
        variant,
        onDismiss
    }: {
        message?: string;
        description?: string;
        variant: IMessageProps['variant'];
        onDismiss?: () => void;
    }) => void;
}

const TITLE = {
    alert: 'Error',
    notice: 'Warning',
    info: 'Info',
    success: 'Success'
} as const;

const ToasterContainer = React.forwardRef<ToasterContainerRef>((props, ref) => {
    const [toasts, setToasts] = React.useState<IMessageProps[]>([]);

    React.useImperativeHandle(ref, () => ({
        showToast: ({ message, description, variant, onDismiss }) => {
            const newToast: IMessageProps = {
                className: 'tw-pointer-events-auto',
                message: message ?? TITLE[variant ?? 'info'],
                description,
                variant,
                onClose: () => {
                    setToasts(v => v.filter(t => t !== newToast));
                    onDismiss?.();
                }
            };
            setToasts([...toasts, newToast]);
        }
    }));

    return (
        <React.Fragment>
            <div className="toast-container tw-fixed tw-bottom-2 tw-right-2 tw-flex tw-flex-col tw-gap-2">
                {toasts.map((toast, i) => (
                    <Message key={i} {...toast} />
                ))}
            </div>
        </React.Fragment>
    );
});

export default ToasterContainer;
